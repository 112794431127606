import React from "react";
import { graphql } from "gatsby";
import { PrivacyCard } from "../components/PageComps/CommonComps";
import StandardSEO from "../components/common/seo/StandardSEO";

const CustomerTCsPage = ({ data }) => {
  const { sanityCustomerTCsPage } = data;

  return <PrivacyCard data={sanityCustomerTCsPage} />;
};

export default CustomerTCsPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityCustomerTCsPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityCustomerTCsPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      point: privacyPolicy
      updatedAt: _updatedAt
    }
  }
`;
